import React from "react";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useGetAvailableChallengesQuery } from "services/graphql";
import { Waypoint } from "app/components/Waypoint";
import { H1 } from "app/components/Typography";
import CommunityHeader from "./CommunityHeader";
import ChallengeThumbnail from "./ChallengeThumbnail";
import ChallengeInfo from "./ChallengeInfo";

const PER_PAGE = 10;

export default () => {
  const { data = {}, loading, fetchMore } = useGetAvailableChallengesQuery({
    variables: {
      pagination: {
        first: PER_PAGE,
      },
    },
  });

  if (loading) {
    return <LoaderCentered />;
  }

  const challengesData = data.challengesAvailable;

  const handleFetchMore = async () => {
    const lastEdge = challengesData.edges[challengesData.edges.length - 1];
    if (lastEdge) {
      await fetchMore({
        variables: {
          pagination: {
            first: PER_PAGE,
            after: lastEdge.cursor,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const previousEntry = previousResult.challengesAvailable;
          const newEdges = fetchMoreResult.challengesAvailable.edges;

          return {
            challengesAvailable: {
              ...fetchMoreResult.challengesAvailable,
              edges: [...previousEntry.edges, ...newEdges],
            },
          };
        },
      });
    }
  };

  const contentWidthSizes = {
    _: "375px",
    lg: "756px",
  };

  return (
    <Flex width="100%" alignItems="center" flexDirection="column">
      <Flex
        flexDirection="column"
        mt="48px"
        mb={5}
        width="90%"
        alignItems="center"
      >
        <H1
          fontSize={{ _: "32px", sm: "50px" }}
          mb="36px"
          width={contentWidthSizes}
          maxWidth="100%"
        >
          STEEZY Challenges
        </H1>
        {challengesData.edges.map(edge => {
          const challenge = edge.node;
          return (
            <Flex
              alignItems="center"
              flexDirection="column"
              backgroundColor="monochrome.1"
              pt="48px"
              pb="48px"
              borderRadius="10px"
              mb="36px"
              width="100%"
              key={challenge.id}
            >
              <Div width={contentWidthSizes} maxWidth="100%">
                <CommunityHeader challenge={challenge} />
                <Flex width="100%" justifyContent="center" flexWrap="wrap">
                  <ChallengeThumbnail challenge={challenge} />
                  <ChallengeInfo challenge={challenge} />
                </Flex>
              </Div>
            </Flex>
          );
        })}
        <Waypoint
          hasMore={challengesData.pageInfo.hasNextPage}
          fetchData={handleFetchMore}
        />
      </Flex>
    </Flex>
  );
};
